import React, { Component } from "react"
import { Link } from "gatsby"

class Header extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <header id="top" className={`mainheader ${this.props.classes}`}>
        <div className="container-fluid-fluid pad-l-big pad-r-big">
          <div className="row">
            <div id="top-logo" className="logo-big "><a href="/"> </a></div>
            <nav className="navbar" role="navigation">
              <button type="button" className="navbar-toggle navbar-toggle--x "><span>toggle menu</span></button>
              <div className="collapse navbar-collapse">
                <ul id="nav-bar" className="nav navbar-nav">
                  <li><Link to="/">główna</Link></li>
                  <li><Link to="/o-mnie/">o mnie</Link></li>
                  <li><Link to="/opinie/">opinie</Link></li>
                  <li><Link to="/cennik/">cennik</Link></li>
                  <li><Link to="/zabiegi/korekcja-nosa-i-przegrody/">zabiegi</Link></li>
                  <li><Link to="/galeria/">galeria</Link></li>
                  <li><Link to="/kontakt/">kontakt</Link></li>
                </ul>
              </div>
            </nav>
            <div className="pull-right top-contact"><span className="top-contact-ico top-contact-ico-fb">
              <a href="https://www.facebook.com/Centrummpastucha/" target="blank"
                 rel="nofollow">Odwiedź nas na facebooku</a></span> <span
                 className="top-contact-ico top-contact-ico-marker">Dębowa 3, Dobra Szczecińska</span> <span
                 className="top-contact-ico top-contact-ico-contact">Zadzwoń: 91 311 22 28</span>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
