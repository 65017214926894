import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby-link"

function SEO({ description, url, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'pl',
      }}
      title={title}
      meta={[
        {
          charset: `UTF-8`,
        },
        {
          'http-equiv': 'X-UA-Compatible'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, user-scalable=0'
        },
        {
          name: 'description',
          content: description
        },
        {
          name: 'og:locale',
          content: 'pl_PL'
        },
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:title',
          content: title
        },
        {
          name: 'og:description',
          content: description
        },
        {
          name: 'og:url',
          content: url
        },
        {
          name: 'og:site_name',
          content: title
        },
        {
          name: 'og:image',
          content: 'https://mpastucha.pl/images/og.jpg'
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:description',
          content: description
        },
        {
          name: 'twitter:title',
          content: title
        }
      ]}
      link={[
        {
          rel: "icon",
          type: 'image/x-icon',
          href: withPrefix('images/favicon.ico'),
        },
        {
          rel: "shortcut icon",
          type: 'image/x-icon',
          href: withPrefix('images/favicon.ico'),
        },
        {
          rel: "apple-touch-icon",
          type: 'image/x-icon',
          href: withPrefix('images/apple-touch-icon.png'),
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css?family=Lato%3A400%2C400italic%2C700%2C700italic%2C300%2C300italic&subset=latin%2Clatin-ext&ver=5.2.10",
          type: "text/css",
          media: "all",
        }
      ]}
      script={[
        { src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAorgOd0IYZrjPCP8pcvk6z7SVY-PDAbvE' },
        { src: '/scripts/vendors.js' },
        { src: '/scripts/main.js' },
        // { defer: true, src: '/scripts/shortcodes.js' },
      ]}
    />
  )
};

export default SEO
