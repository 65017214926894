import React from "react"

export default function Aside({ posts, actual = '', categories=['Twarz', 'Tułów', 'Kończyny', 'Inne'] }) {
  return (
    <aside className="col-3md bg-dark sidebar txt-white">
      <nav id="sidebar-menu">
        <div className="mdplus-only">
          { categories.map((title, key) => (
            <>
              <h3 key={key} dangerouslySetInnerHTML={{__html: title}} />
              <ul>
                { posts
                  .filter((element) => element.category === title)
                  .map((element, subkey) => (
                    <li>
                      <a href={element.slug} dangerouslySetInnerHTML={{__html: element.title}} {...(actual === element.slug ? {className: "active"} : {})}/>
                    </li>
                  ))
                }
              </ul>
            </>
          )) }
        </div>
      </nav>
    </aside>
  )
}
