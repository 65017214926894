import React from "react"
import PropTypes from "prop-types"
import "../../styles/main.scss"
import Header from "./header"
import Cookies from "./cookies"
import Footer from "./footer"
import PhotoswipeContainer from "../pswp"

const Layout = ({ children, headerClasses, classes }) => {
  return (
    <div className={classes}>
      <div className="rwd-tester"></div>
      <Header classes={headerClasses} />

      <main>
        {children}
      </main>
      <Footer />
      <Cookies />
      <PhotoswipeContainer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
