import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="footer">
      <section className="fullwidth bg-dark footer-l">
        <div className="container-fluid pad-l pad-r">
          <div className="sct notoppad nobotpad">
            <div className="row">
              <div className="col-4sm">
                <ul className="nav navbar-nav nav-footer">
                  <li><Link to="/o-mnie/">o mnie</Link></li>
                  <li><Link to="/opinie/">opinie</Link></li>
                  <li><Link to="/cennik/">cennik</Link></li>
                  <li><Link to="/zabiegi/korekcja-nosa-i-przegrody/">zabiegi</Link></li>
                  <li><Link to="/galeria/">galeria</Link></li>
                  <li><Link to="/kontakt/">kontakt</Link></li>
                </ul>
              </div>
              <div className="col-6sm">
                <div className="top-contact-f"> <span className="top-contact-ico top-contact-ico-marker">Dębowa 3, Dobra Szczecińska</span> <span className="top-contact-ico top-contact-ico-contact smplus-only-ib">Zadzwoń: 91 311 22 28</span></div>
              </div>
              <div className="col-2sm logo">
                <p className="txt-small txt-al-r ">O nasz wizerunek dba:</p>
                <a href="https://brandmed.com/" target="_blank">
                  <div className="footer-logo"><img src="/images/logo_brandmed.svg" alt="Brandmed logo" /></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
