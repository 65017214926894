import React, {Component} from "react"

class Cookies extends Component {

  constructor() {
    super();
  }

  setCookie() {
    const c_name = 'cookie_approval_pastucha';
    const value = true;
    const exdays = 60;

    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ("; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;

    document.querySelector('.cookies_bg').style.display = 'none';
  }

  render() {
    return (
      <>
        <div className="ad-modal" id="ad-modal">
          <div className="ad-modal-inner">
            <div className="ad-modal-close js-close-ad-modal"><span>zamknij</span> <img
              src="/images/x.svg" alt="Ikona zamknij" /></div>
            <div className="ad-modal-content">
              <img src="/images/mlogo.png" alt="M logo" />
              <div className="ad-modal-copy">
                <h3> Użytkowniku,</h3>
                <p> Zanim klikniesz „Przejdź do serwisu” lub zamkniesz to okno, prosimy o przeczytanie tej informacji.
                  Prosimy w niej o Twoją dobrowolną zgodę na przetwarzanie Twoich danych osobowych przez naszych partnerów
                  biznesowych oraz przekazujemy informacje o tzw. cookies i o przetwarzaniu przez nas Twoich danych
                  osobowych. Klikając „Przejdź do serwisu” lub zamykając okno przez kliknięcie w znaczek X, zgadzasz się
                  na poniższe. Możesz też odmówić zgody lub ograniczyć jej zakres.</p>
                <div className="ad-modal-buttons"><a href="/uploads/2018/06/pastucha-cookies.pdf"
                                                     className="outline">przeczytaj więcej</a> <a href="#"
                                                                                                  className="js-close-ad-modal">przejdź
                  do serwisu</a></div>
              </div>
            </div>
          </div>
        </div>
        <div className="cookies_bg">
          <div className="container-fluid pad-l pad-r-big">
            <div className="row">
              <div className="col-12">
                <p>Ta strona używa ciasteczek (cookies), dzięki którym nasz serwis może działać lepiej. Przeczytaj <a
                  href="/uploads/2018/06/pastucha-cookies.pdf" target="_blank"
                  rel="noopener">politykę wykorzystywania plików cookies</a> w naszych serwisach.</p>
                <a href="#" className="close" onClick={this.setCookie}>
                  <img src="/images/x.svg" alt="Ikona zamknij" /> </a>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Cookies
